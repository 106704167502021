<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓储管理</el-breadcrumb-item>
      <el-breadcrumb-item>库存预警</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div class="top">
        <div class="tops">
          <span>货物编号 :</span>
          <el-autocomplete
            clearable
            :trigger-on-focus="false"
            placeholder="请输入货物编号"
            v-model="shaibian"
            :fetch-suggestions="bianvalidateCounts"
            value-key="goods_no"
            @select="bianhandleSelect($event, '货物编号')"
            @clear="chu"
          ></el-autocomplete>
        </div>
        <div class="tops">
          <span>货物名称 :</span>
          <el-autocomplete
            clearable
            :trigger-on-focus="false"
            placeholder="请输入货物名称"
            v-model="shainame"
            :fetch-suggestions="huowuvalidateCounts"
            value-key="name"
            @select="handleSelect($event, '货物名称')"
            @clear="qingchu"
          ></el-autocomplete>
        </div>
        <div class="tops">
          <span>货物类别 :</span>
          <el-cascader v-model="type" :options="leilist" :show-all-levels="false" @change="leichenge" :props="propss" clearable @clear="qing"></el-cascader>
        </div>
        <div class="tops">
          <span>仓库名称 :</span>
          <el-autocomplete
            clearable
            :trigger-on-focus="false"
            placeholder="请输入仓库关键字"
            v-model="house"
            :fetch-suggestions="cangvalidateCounts"
            value-key="name"
            @select="canghandleSelect($event)"
            @clear="cangqing"
          ></el-autocomplete>
        </div>
      </div>
      <!-- 表格开始 -->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="goods_no" label="货物编号" width="150"></el-table-column>
        <el-table-column prop="goods_name" label="货物名称"></el-table-column>
        <el-table-column prop="type" label="货物类别" width="230"></el-table-column>
        <el-table-column prop="unit" label="货物单位" align="center" width="150"></el-table-column>
        <el-table-column prop="house_name" label="仓库名称" width="150"></el-table-column>
        <el-table-column prop="goods_num" label="库存数量" align="center" width="100"></el-table-column>
        <el-table-column prop="avail_num" label="可出库存" align="center" width="100"></el-table-column>
        <el-table-column prop="warn_num" label="库存预警值" align="center" width="100"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="editfun(scope.row.goods_id, scope.row.house_id, scope.row.warn_num)">编 辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!-- 弹窗 -->
    <el-dialog title="预警信息设置" :visible.sync="dialogVisible" width="40%" :before-close="handleClose" center>
      <div class="tan">
        <div class="let">预警值</div>
        <div class="el-select"><el-input v-model="warn_nums" type="number" placeholder="请输入预警值"></el-input></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quanxiao()">取 消</el-button>
        <el-button type="primary" @click="queding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { Shopsou, Huoshu, Getpeilist, Cangsou, Cangyulist, Cangyuzhi } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      goods_id: '',
      house_id: '',
      shaibian: '',
      type: '',
      shainame: '',
      goods_type: '',
      house: '',
      warn_nums: '',            // 预警值
      dialogVisible: false,
      huolist: [],              // 货物模糊搜索数据
      bianlist: [],             // 货品编号
      leilist: [],              // 货物类型
      storelist: [],            // 仓库
      tableData: [],
      propss: {
        label: 'title',
        children: 'list',
        value: 'id'
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.Huowulei() // 货物类型筛选
    this.Cangyulists()
  },
  methods: {
    // 确定
    queding() {
      if (this.warn_nums !== '' && this.warn_nums >= 0) {
        this.Cangyujingzhi()
      } else {
        this.$message({
          message: '预警值不能为空并且不能小于0',
          type: 'warning'
        })
      }
    },
    // 取消
    quanxiao() {
      this.warn_nums = ''
      this.dialogVisible = false
    },

    // 弹窗关闭
    handleClose() {
      this.quanxiao()
    },
    // 点击编辑
    editfun(goods_id, house_id, warn_num) {
      this.goods_id = goods_id
      this.house_id = house_id
      this.warn_nums = warn_num
      this.dialogVisible = true
    },
    // 名称筛选清除
    qingchu() {
      this.shainame = ''
      this.Cangyulists()
    },

    // 类型清楚
    qing() {
      this.goods_type = ''
      this.type = ''
      this.Cangyulists()
    },

    // 编号筛选清除
    chu() {
      this.shaibian = ''
      this.Cangyulists()
    },

    // 搜索仓库清除
    cangqing() {
      this.house = ''
      this.Cangyulists()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Cangyulists()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Cangyulists()
    },

    leichenge(e) {
      //   console.log(e)
      this.goods_type = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
      this.Cangyulists()
    },

    // 货品搜索选中
    handleSelect(e) {
      console.log(e)
      this.Cangyulists()
    },

    bianhandleSelect(e) {
      console.log(e)
      this.Cangyulists()
    },
    // 搜索仓库
    cangvalidateCounts(queryString, cb) {
      this.Cang(queryString, cb)
    },
    // 搜索仓库选中
    canghandleSelect(e) {
      console.log(e)
      this.Cangyulists()
    },

    // 搜商品的数据
    huowuvalidateCounts(queryString, cb) {
      this.Drops(queryString, cb)
    },
    bianvalidateCounts(queryString, cb) {
      this.Brops(queryString, cb)
    },

    // 商品搜索
    async Drops(name, cd) {
      const { data } = await Shopsou({ name })
      this.huolist = data.data
      cd(data.data)
    },

    // 商品编号搜索
    async Brops(goods_no, cd) {
      const { data } = await Getpeilist({ goods_no })
      this.bianlist = data.data.data
      cd(data.data.data)
    },

    // 仓库类型
    async Huowulei() {
      const { data } = await Huoshu()
      this.leilist = data.data
    },

    // 仓库搜索
    async Cang(name, cd) {
      const { data } = await Cangsou({ name })
      // console.log(data.data)
      this.storelist = data.data
      cd(data.data)
    },

    // 仓库预警列表
    async Cangyulists() {
      const { data } = await Cangyulist({ ...this.pageData, goods_name: this.shainame, goods_no: this.shaibian, goods_type: this.goods_type, house_name: this.house })
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 仓库预警值编辑
    async Cangyujingzhi() {
      const { data } = await Cangyuzhi({ goods_id: this.goods_id, house_id: this.house_id, warn_num: this.warn_nums })
      console.log(data)
      this.getanpostfun(data, '修改', this.handleClose(), this.Cangyulists())
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`, this.handleClose())
      }
    }
  }
}
</script>

<style>
.tops {
  margin-left: 30px;
}
.top {
  display: flex;
  align-items: center;
}
.let {
  width: 50px;
  margin-left: 15px;
}
.tan {
  display: flex;
  align-items: center;
}
</style>